<template>
  <div class="ai-switch">
    <label for="ai-switch" class="label">{{ label }}</label>
    <div class="check-switch">
      <input
        id="ai-switch"
        class="input"
        type="checkbox"
        v-model="switchValue"
      />
      <label for="ai-switch" class="slider"></label>
    </div>
  </div>  
</template>

<script>
export default {
  name: 'InputSwitch',
  props: ['value', 'label'],
  data() {
    return {}
  },
  computed: {
    switchValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style scoped>
.ai-switch {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  padding: 8px 0px 8px 20px;
  margin-right: 20px;
  box-sizing: border-box;
}
.check-switch {
  display: flex;
  align-items: center;
}
.ai-switch .slider {
  position: relative;
  display: inline-block;
  height: 12px;
  width: 25px;
  border-radius: 20px;
  cursor: pointer;
  background: #546267;
  transition: all 0.2s cubic-bezier(.75,.05,.07,1.05);
}
.ai-switch .slider:after {
  background: #fefefe;
  position: absolute;
  left: -8px;
  top: -2px;
  display: block;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  content: '';
  transition: all 0.2s;
}
.ai-switch .label {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  margin-right: 16px;
  line-height: 35px;
  white-space: nowrap;
}
.ai-switch .input {
  display: none;
}

.ai-switch .input:checked ~ .slider:after {
  left: 16px;
}
.ai-switch .input:checked ~ .slider {
  background: #FFC600;
}

</style>